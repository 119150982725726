:root {
    --bg: #F7F3E9;
    --pale: #FBF9F3;
    --themeColor: #853C19;
    --gradientDark: linear-gradient(90deg, rgba(134, 60, 25, 1) 0%, rgba(55, 26, 12, 1) 100%);
    --gradientLight: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(232, 216, 184, 1) 100%);
    --white: #FFFCF6;
    --yellow: #FFF1A9;
    --text: #4D6075;
    --placeholder: #A49C9C;

}