@media (max-width: 1399px) {
    // .ticketWorld {
    //     right: 75px;
    // }

    .about-text-card {
        padding-bottom: 60px !important;
    }



    .ticketContainer {
        // padding-top: 80px;
        // height: 375px;

        .ticket-text-card {

            .ticketDetailWrapper .ticketDetailCol * {
                white-space: normal;
            }

            .ticketDetailWrapper {
                margin-top: 5px;
                gap: 10px;

                .ticketDetailCol {
                    .txtDest {
                        line-height: 25px;
                    }

                    .txtCont {
                        line-height: 17px;
                    }
                }

                .ticketFromDetailCol {
                    max-width: 260px;
                }

                .ticketDetailDesCol {
                    max-width: 200px;
                }
            }

            .abtUserWrapper {
                margin-top: 25px;
            }
        }

        .boardingWrapper .boardingContainer {
            padding: 20px 40px 0 40px;
        }
    }

    .servicesFluid {

        .planRow {
            padding: 0 30px 0 0px;
        }

        .offerBanner {
            background-position: top 0 left -40px;
        }

        .offerBanner1 {
            padding-left: 20px;
        }

        .offerBanner2 {
            padding-left: 220px;
        }
    }

    .whyChooseWrapper {
        padding: 40px 50px 150px 50px;
    }

}

@media (max-width: 1199px) {

    .banner-bg {
        .thumbnailSlides {
            width: 540px;
        }
    }

    // .ticketWorld {
    //     right: 50px;
    // }

    .sliderContentWrapper {
        max-width: 500px !important;
    }

    .ticketContainer {
        padding-top: 60px;
        padding-left: 30px;

        // height: 315px;
        .boardingWrapper {
            bottom: -5px;
            right: 100px !important;
        }

        .ticket-text-card {

            .ticketDetailWrapper {
                margin-top: 5px;
                gap: 10px;

                .ticketDetailDesCol {
                    max-width: 140px;
                }
            }

            .abtUserWrapper {
                margin-top: 12px;
            }
        }

        .boardingWrapper {
            .boardingContainer {
                padding: 20px 0px 0 20px;
                display: flex;
                flex-wrap: wrap;
                width: 245px;

                .getBPassDet {
                    line-height: 26px;
                }

                button {
                    margin-top: 10px;
                }
            }

        }
    }

    .servicesFluid {
        .planRow {
            padding: 0;
        }

        .offerBanner1 {
            padding-left: 20px;
        }

        .offerBanner2 {
            background-position: top 0 left -130px;
            padding-left: 130px;
            padding-right: 10px;
        }
    }

    .whyChooseWrapper {
        padding: 40px 10px 150px 10px;

        .whyChooseTitle {
            font-size: 20px;
        }
    }

    .contactFluid {
        .contactContainer {
            .contactImg1 {
                right: -20px;
            }

            .contactImg2 {
                left: 0px;
            }
        }

        .contactDesc {
            padding: 0 230px 0 230px;
        }
    }

    footer {
        .footerContentContainer {
            gap: 10px;

            .footer-list {
                gap: 20px;
            }
        }

        .footer-BG {
            img {
                max-width: 410px;
            }
        }
    }

}


@media (max-width: 991px) {
    header {
        padding: 0px 0 !important;

        &.sticky {
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            .showToggle {
                top: 88px;
            }
        }

        padding-left: 0 !important;
        padding-right: 0 !important;

        .nav-bar-list {
            display: none;
        }

        .toggleBtn {
            display: block;
            cursor: pointer;

            svg {
                color: var(--white);
            }
        }

        .showToggle {
            position: absolute;
            top: 80px;
            left: 0;
            background: var(--gradientDark);
            color: var(--white);
            width: 100%;
            display: block;
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;

            &>div {
                border-bottom: 1px solid #d8d8d8;
                outline: none;
                width: 100%;

                a {
                    padding: 15px 10px;
                    padding-bottom: 15px;
                    display: flex;
                    width: 100%;
                    color: var(--themeColor) !important;
                }

            }

        }

    }

    .sticky .nav-bar-list a:hover:after,
    .sticky .nav-bar-list a.active:after {
        content: "" !important;
        position: absolute !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        border: none !important;
        width: 70% !important;
        margin: auto !important;
    }



    .common-space {
        padding: 30px 0;
    }

    .container-fluid .container.containerMainSlider {
        justify-content: center !important;
        text-align: center;
    }

    .banner-bg .sliderContentWrapper .subHeading::after {
        right: 0 !important;
        margin: auto !important;
    }

    .main-heading {
        font-size: 28px !important;
        line-height: 45px !important;
    }

    p {
        font-size: 15px !important;
        line-height: normal !important;
    }

    .about-text-card {
        gap: 40px;

        &>div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

            span img {
                max-width: 400px;
            }

            .tStripe::before {
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    // .ticket-world-img {
    //     right: 20px;
    //     width: 90px;
    // }
    .slick-next {
        right: -65px;
    }

    .slick-prev {
        left: -65px;
    }

    .branch-locations {
        justify-content: center;
        row-gap: 30px !important;


    }

    .branch-wrap {

        margin: 25px 0;
    }

    .union {
        display: none !important;
    }

    .ticketContainer {
        padding: 20px;
        border-radius: 20px;
        height: auto;
        flex-wrap: wrap;

        .boardingWrapper {
            width: 140px !important;
            height: 260px;
            bottom: -18px;
            right: 50px !important;
        }

        .ticket-text-card {
            width: 100%;
            padding-right: 0;

            .ticketDetailWrapper {
                flex-wrap: wrap;
                margin-top: 5px;
                gap: 10px;

                .ticketDetailCol {
                    max-width: 100%;
                    min-width: 100%;
                }

                .ticketDetailDesCol {
                    display: none;
                }

                .ticketToDetailCol {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

        }

        .ticket-slider {
            display: none !important;
        }

        .boardingWrapper {
            .boardingContainer {
                padding: 30px 0px 0 0px;
                width: 100%;

                .boardingHeading {
                    color: var(--white);
                    line-height: 30px;
                }

                .getBPassDet {
                    color: var(--white);
                }

                button:hover {
                    color: var(--white);
                    border: 1px solid var(--white);

                    svg [fill] {
                        fill: var(--white);
                    }
                }
            }

        }
    }

    .servicesFluid {
        padding-top: 20px;

        .offersRow {
            margin-top: 20px;
        }

        .offerBanner {
            background-position: top 0 left 0px;
        }

        .offerBanner2 {
            padding-left: 300px;
        }

        .planRow {

            .planWrapper {
                margin-top: 10px;
                margin-bottom: 10px;

            }

            .planCol {
                &> :first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .whyChooseWrapper {
        padding: 20px 10px 40px 10px;

        .whyChooseRow {
            margin-top: 25px;

            &>div {
                margin: 20px 0 20px 0;
            }
        }
    }

    .expertiseFluid {
        .journeyDesc {
            margin-top: 20px;
            padding: 0 0px 0 0px;
        }

        .serviceRow {
            margin-top: 20px;

            .servicesWrapper {
                height: 200px;
            }
        }
    }

    .contactFluid {
        padding-top: 10px;
        background-position: top 30px left 50%;

        .contactContainer {
            margin-top: 20px;

            .contactImg {
                display: none;
            }

            .contactRow {
                padding-left: 10px;
                padding-right: 10px;

                iframe {
                    height: 300px;
                }
            }

            .mapWrapper {
                padding: 30px 0 20px 0 !important;
            }

            .contactFormWrapper {
                margin-top: 0;
            }
        }

        .contactDesc {
            padding: 0 0px 0 0px;
            margin-top: 30px;
        }
    }

    footer {
        .footer-BG {
            display: none;
        }

        .footer-list {
            display: none !important;
        }
    }

    .banner-bg {
        min-height: auto;
        max-height: auto;
        height: auto;

        .slick-slider {
            .slick-arrow {
                display: none !important;
            }

            .slick-list {
                .slick-track {
                    .slick-slide {
                        &>div {
                            .container-fluid {
                                .container.containerMainSlider {

                                    .sliderContentWrapper {
                                        max-width: 100%;
                                        width: 100%;
                                        margin-top: 30px;

                                        .sliderHeading {
                                            text-align: center !important;
                                            font-size: 35px;
                                        }

                                        .sliderdetail {
                                            text-align: center !important;
                                        }

                                        .sliderBtn {
                                            justify-content: center !important;

                                        }

                                    }
                                }
                            }
                        }
                    }
                }

                .mainSlide {
                    background-size: cover;
                    background-position: center;
                    padding-top: 100px;
                    padding-bottom: 260px;
                }

            }
        }

        .thumbnailSlides {
            padding-left: 0;
            padding-right: 0;
            left: 0 !important;
            right: 0 !important;
            margin: auto !important;
            top: 300px !important;
            bottom: auto !important;


        }
    }




}

@media (max-width: 767px) {
    header {
        .logoHeader {
            & svg {
                width: 100px !important;
                height: 70px !important;
            }
        }
    }

    .social-link {
        display: none !important;
    }

    .img-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }

    .banner-bg .thumbnailSlides .mainThumb::before {
        width: 92% !important;
    }

    .boardingWrapper {
        display: none !important;
    }

    .ticketContainer {
        .ticket-text-card {
            width: 100%;
        }

        .ticket-text-card {
            & div {
                font-size: 17px !important;
            }

            // .abtUserWrapper {
            //     flex-wrap: wrap;
            //     gap: 15px;

            //     .abtUserCol {
            //         flex-direction: column;
            //         width: fit-content;
            //     }
            // }
        }

    }

    .servicesFluid {
        .offerBanner {
            background-position: top 0 left -40px;
        }

        .offerBanner2 {
            padding-left: 190px;
            background-position: top 0 left -80px;
        }
    }

    .tabWrapper {
        flex-wrap: wrap;
        overflow-x: auto !important;
        gap: 5px !important;
        flex-direction: column-reverse !important;

        & div {
            width: 100%;
        }
    }

    .TabButtonWrapper {
        padding: 8px 20px;
        margin: 4px 0 !important;
        position: relative;
        cursor: pointer;
    }

    .whyChooseWrapper {
        padding: 10px 10px 30px 10px;

        .whyChooseHeading {
            font-size: 25px !important;


        }

        .whyChooseRow {
            margin-top: 0px;

            &>div {
                margin: 10px 0 10px 0;
            }

            .whyChooseText {
                font-size: 27px;
                margin-top: 0px;
            }
        }
    }

    .flip-card-front {
        display: none !important;
    }

    .flip-card-back {
        transform: rotateY(0deg) !important;
    }

    .flip-card:hover .flip-card-inner,
    .flip-card:focus .flip-card-inner {

        transform: rotateY(0deg) !important;
    }

    footer {
        .footerContentContainer {
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

            .footer-list {
                gap: 15px;
            }

            .Footer-social-links {
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;

                .socialLinksContainer {
                    justify-content: center;
                }

                .footer-list {
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }

        .footer-BG {
            display: none;
        }
    }

    .contact-wrapper {
        width: auto !important;
    }
}

@media (max-width: 640px) {
    .expertiseFluid {
        .planeImg {
            span {
                img {
                    width: 400px;
                }
            }
        }
    }

    .banner-bg {
        min-height: auto;
        max-height: auto;
        height: auto;

        .slick-slider {
            .slick-arrow {
                display: none !important;
            }

            .slick-list {
                .slick-track {
                    .slick-slide {
                        &>div {
                            .container-fluid {
                                .container.containerMainSlider {
                                    height: 160px;

                                    .sliderContentWrapper {
                                        max-width: 100%;
                                        width: 100%;

                                        .sliderHeading {
                                            text-align: center !important;
                                            font-size: 35px;
                                            line-height: 40px;
                                        }

                                        .sliderdetail {
                                            text-align: center !important;
                                        }

                                        .sliderBtn {
                                            justify-content: center !important;

                                        }

                                    }
                                }
                            }
                        }
                    }
                }

                .mainSlide {
                    background-size: cover;
                    background-position: center;
                    padding-top: 100px;
                    padding-bottom: 165px;
                }

            }
        }

        .thumbnailSlides {
            top: 310px !important;
            width: 100%;

        }
    }

    .aboutFluid {
        padding-top: 130px !important;
    }
}


@media (max-width: 576px) {
    header.sticky .showToggle {
        top: 74px;
    }

    .headerWhatsapp {
        display: none !important;
    }


    .main-heading {
        font-size: 25px !important;
        line-height: 30px !important;
    }

    .about-text-card .main-heading {

        margin-bottom: 0 !important;
    }

    .slick-prev {
        left: 0px !important;
    }

    .slick-next {
        right: 0px !important;
    }

    .subHeading {
        font-size: 16px !important;
        letter-spacing: 0px !important;
    }

    .sliderdetail {
        font-size: 15px !important;
    }

    .sliderHeading {
        text-align: center !important;
        font-size: 25px !important;
        line-height: 30px !important;
    }

    button>span {
        font-size: 14px !important;
    }

    .slick-prev,
    .slick-next {
        width: 26px !important;
        height: 26px !important;
        z-index: 10;
    }

    .servicesFluid {
        .offerBanner {
            background-position: top 0 left 0px;
            background-size: 190% 100%;
            padding-left: 5px;
            padding-right: 5px;

            .offerContentContainer,
            .offerContentContainer>* {
                max-width: 100%;
                min-width: 100%;
                justify-content: center;
                text-align: center;
            }
        }

        .offerBanner2 {
            background-position: top 0 right 0px;
        }

    }

    .product-bg {
        background-size: 85% 85%;
    }

    .expertiseFluid {
        .planeImg {
            span {
                img {
                    width: 300px;
                }
            }
        }
    }

    header.sticky {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .modal-title span {
        font-size: 22px !important;
    }

    footer {
        .footerContentContainer {
            .footer-list {
                gap: 10px;
            }
        }

        .footer-content {
            flex-direction: column !important;
        }

        .footer-revised {
            text-align: center;
            display: flex;
            flex-direction: column;
        }

        // .container {
        //     padding-bottom: 80px;
        // }

    }
}


@media (max-width: 480px) {
    .headerWhatsapp {
        display: none !important;
    }

    .abtUserColName {
        width: 100% !important;
    }

    #contact .px-4 {
        padding: 0px 6px !important;
    }

    .main-heading {
        font-size: 23px !important;
        line-height: 30px !important;
    }

    p {
        font-size: 14px !important;
        line-height: normal !important;
    }

    .whyChooseTitle {
        font-size: 18px !important;
    }

    .jHhlej .whyChooseWrapper {
        padding: 15px 5px !important;
    }

}


@media (max-width: 350px) {
    .servicesFluid {
        button {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
    }

    .expertiseFluid {
        .planeImg {
            span {
                img {
                    width: 280px;
                }
            }
        }
    }
}



/**********************RTL*******************/

@media (max-width: 1399px) {
    body.rtl {
        .ticketContainer {

            .ticket-text-card {

                .ticketDetailWrapper {
                    margin-top: 15px;

                    .ticketDetailCol {

                        .txtDest {
                            line-height: 32px;
                        }

                        .txtDate {
                            line-height: 25px;
                        }
                    }

                    .ticketDetailDesCol {
                        max-width: 250px;
                    }

                }

                .abtUserWrapper {
                    margin-top: 35px;

                }
            }

            .boardingWrapper {
                .boardingContainer {
                    padding-top: 20px;
                }
            }

        }

        .servicesFluid {
            .offersRow {
                .offerBanner1 {
                    padding-right: 240px;
                }
            }

            .planRow {
                padding: 0 0px 0 30px;
            }
        }
    }
}


@media (max-width: 1199px) {
    body.rtl {
        .ticketContainer {

            .ticket-text-card {

                .ticketDetailWrapper {
                    margin-top: 10px;

                    .ticketDetailCol {

                        .txtFromTo {
                            line-height: 20px;
                        }

                        .txtDest {
                            line-height: 30px;
                        }

                        .txtDate {
                            line-height: 20px;
                        }
                    }

                    .ticketDetailDesCol {
                        max-width: 150px;
                    }

                    .ticketFromDetailCol {
                        min-width: 230px;
                    }
                }

                .abtUserWrapper {
                    margin-top: 20px;

                }
            }

            .boardingWrapper {
                .boardingContainer {
                    padding-top: 20px;
                }
            }

        }

        .servicesFluid {
            .offersRow {
                .offerBanner1 {
                    padding-right: 160px;
                }

                .offerBanner2 {
                    background-position: top 0 left -90px;
                    padding-right: 20px;
                }
            }

            .planRow {
                padding: 0 0px 0 0px;
            }
        }

        .banner-bg {

            .slick-slider {

                .slick-list {
                    .slick-track {
                        .slick-slide {
                            &>div {
                                .container-fluid {
                                    .container.containerMainSlider {
                                        .sliderContentWrapper {

                                            .sliderHeading {
                                                font-size: 45px;
                                                line-height: 50px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }

        }

    }
}

@media (max-width: 991px) {
    body.rtl {
        .ticketFluid {
            .ticketContainer {
                padding-left: 15px;
                padding-right: 15px;
                // background-image: url(../images/png/ticketBgRes.png);

                .ticket-text-card {

                    .ticketDetailWrapper {
                        margin-top: 10px;

                        .ticketDetailCol {

                            .txtFromTo {
                                line-height: 25px;
                            }

                            .txtDest {
                                line-height: 40px;
                            }

                            .txtDate {
                                line-height: 25px;
                            }
                        }

                        .ticketFromDetailCol {
                            min-width: 100%;
                        }
                    }

                    .abtUserWrapper {
                        margin-top: 20px;

                    }
                }

                .boardingWrapper {
                    .boardingContainer {
                        padding-top: 30px;

                        .boardingHeading {
                            width: 100%;
                        }

                        .getBPassDet {
                            width: 100%;
                        }

                        button {
                            &:hover span {
                                color: var(--white) !important;
                            }
                        }

                    }
                }

            }
        }

        .servicesFluid {
            .offersRow {
                .offerBanner1 {
                    padding-right: 350px;
                }

                .offerBanner2 {
                    background-position: top 0 left 0px;
                    padding-right: 30px;
                }
            }
        }

        .whyChooseWrapper {
            .whyChooseTitle {
                font-size: 22px;
            }
        }
    }
}

@media (max-width: 767px) {
    body.rtl {
        .servicesFluid {
            .offersRow {
                .offerBanner1 {
                    padding-right: 200px;
                }

                .offerBanner2 {
                    background-position: top 0 left -50px;
                    padding-right: 20px;
                }
            }
        }

    }
}


@media (max-width: 575px) {
    body.rtl {
        .servicesFluid {
            .offersRow {
                .offerBanner1 {
                    background-position: top 0 left 0px;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .offerBanner2 {
                    background-position: top 0 right 0px;
                    padding-right: 10px;
                    padding-left: 10px;
                }
            }
        }

    }
}