@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--bg);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

body #root>div {
    overflow-x: hidden;
}

::placeholder {
    color: #a9a9a9 !important;
}

input[type='text'],
input[type='number'],
input[type='date'] {
    color: var(--darkGrey) !important;
}

img {
    width: 100%;
}


ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none;
}

a {
    text-decoration: none;

}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}


.textGrey {
    color: var(--textGrey);
}

.white {
    color: var(--white);
}

.darkBlue {
    color: var(--darkBlue);
}

.themeColor {
    color: var(--themeColor);
}

.gradientDark {
    background: var(--gradientDark);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.gradientLight {
    background: var(--gradientLight);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}


.textarea-box {
    position: relative;
    box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    -webkit-box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    -moz-box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    border-radius: 20px;
}

.textarea-box>textarea {
    padding: 10px 18px 10px 18px;
    border-radius: 20px;
    background: var(--white);
    height: 105px;
    outline: none;
    border: none;
    width: 100%;
    color: var(--textGrey) !important;
}

.logoHeader {
    transition: all .4s ease-in-out;

    & svg {
        width: 120px;
        height: 80px;
        transition: all .4s ease-in-out;
    }
}

.common-space {
    position: relative;
    padding: 50px 0;
}

.sticky {
    background: var(--gradientDark);
    // filter: drop-shadow(1px 3px 3px var(--gradientLight));
    box-shadow: 0px 0px 5px 0px var(--themeColor);
    opacity: 1 !important;

    .logoHeader {
        & svg {
            width: 100px;
            height: 70px;
        }
    }

    .nav-bar-list a:hover,
    .nav-bar-list a.active {
        background: var(--gradientLight);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        opacity: 1;
    }

    .nav-bar-list a {
        background: var(--gradientLight);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        padding-bottom: 12px;
        opacity: 1 !important;
        cursor: pointer;
        position: relative;
    }

    .nav-bar-list a:hover:after,
    .nav-bar-list a.active:after {
        content: '' !important;
        position: absolute !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        border: 2px solid var(--white) !important;
        width: 70% !important;
        margin: auto !important;
    }

}

.footerLogo {
    & svg {
        width: 100px;
        height: 70px;
    }
}


header {
    .headerWhatsapp {
        button {
            span {
                padding: 0 !important;
                font-family: var(--semiBold) !important;
                transition: all .5s ease-in-out;
                background: var(--gradientDark);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;


                &:hover {
                    background: var(--gradientLight);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                }
            }
        }

    }

    .nav-bar-list {
        display: flex;
        align-items: center;
        gap: 35px;
    }

    .toggleBtn {
        display: none;
    }

    .logoHeader {
        cursor: pointer;
    }
}


.logo-header {
    width: 100px;
}

header.sticky {
    transition: all .4s ease-in-out;
    padding: 14px 0;

    .headerWhatsapp {
        button:hover {
            border: 1px solid var(--white) !important;
        }
    }
}

// Banner start 

.banner-bg {
    width: 100%;
    min-height: 600px;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding-left: 0;
    padding-right: 0;

    .slick-slider {
        height: 100%;

        .slick-arrow {
            display: none !important;
        }

        .slick-list {
            height: 100%;

            .slick-track {
                height: 100%;

                .slick-slide {
                    &>div {
                        height: 100%;

                        .container-fluid {
                            height: 100%;

                            .container.containerMainSlider {
                                display: flex;
                                height: 100%;
                                width: 100%;
                                align-items: center;

                                .sliderContentWrapper {
                                    color: var(--pale);
                                    max-width: 530px;
                                    position: relative;

                                    .subHeading {
                                        position: relative;

                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: -10px;
                                            left: 0;
                                            border: 2px solid var(--pale);
                                            width: 10%;
                                        }

                                    }

                                    .sliderHeading {
                                        font-size: 64px;
                                        font-family: var(--heading);
                                        text-transform: uppercase;
                                        color: var(--pale);
                                        line-height: 1.2;
                                        letter-spacing: 2px;
                                    }

                                    .sliderdetail {
                                        font-size: 20px;
                                        margin-bottom: 10px;
                                        line-height: 25px;
                                        font-family: var(--semiBold);
                                    }

                                    // .sliderBtn {
                                    //     button {
                                    //         background: none;
                                    //         // outline: 1px solid #fff;

                                    //         &:hover {
                                    //             // outline: 1px solid var(--themeColor);
                                    //         }
                                    //     }
                                    // }

                                }
                            }
                        }
                    }
                }
            }

            .mainSlide {
                background-size: cover;
                background-position: center;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: #00000070;
                    width: 100%;
                    height: 100%;
                }
            }

            .londonSlide {
                background-image: url(../images/png/house-1.jpeg);
                background-position: bottom;
            }

            .dubaiSlide {
                background-image: url(../images/png/house-2.jpeg);
                background-position: center;
            }

            .malaysiaSlide {
                background-image: url(../images/png/house-3.jpeg);
                background-position: center;
                background-size: cover;
            }

            .maldivSlide {
                background-image: url(../images/png/pvc-doors.jpeg);
                background-position: bottom;
            }

            // .italySlide {
            //     background-image: url(../images/png/house-2.jpg);
            // }

            // .franceSlide {
            //     background-image: url(../images/png/house-3.jpg);
            // }
        }
    }

    .thumbnailSlides {
        height: 225px;
        width: 750px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        padding: 30px 30px 30px 30px;

        .mainThumb {
            width: 230px;
            height: 225px;

            position: relative;


            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.24);
                width: 90%;
                margin: auto;
                height: 100%;
                border-radius: 20px;
            }

            .container {
                height: 100%;
                border-radius: 20px;
                background-size: cover;
                background-position: center;
                // box-shadow: 0px 7px 9px 0px rgba(0,0,0,0.56);
                // -webkit-box-shadow: 0px 7px 9px 0px rgba(0,0,0,0.56);
                // -moz-box-shadow: 0px 7px 9px 0px rgba(0,0,0,0.56);
                display: flex;
                justify-content: flex-start;
                text-align: left;
                color: var(--white);
                align-items: flex-end;
                padding-bottom: 10px;
                cursor: pointer;

                .sliderThumbWrapper {
                    .sliderThumbDetail {
                        font-size: 13px;
                        position: relative;

                        &:before {
                            content: '';
                            height: 2px;
                            width: 16px;
                            background-color: var(--white);
                            position: absolute;
                            top: -5px;
                            left: 0;
                        }
                    }

                    .sliderThumbHeading {
                        font-size: 19px;
                        line-height: 22px;
                        font-family: var(--semiBold);
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }

        .londonThumb {
            .container {
                background-image: url(../images/png/house-1.jpeg);
                background-position: bottom !important;
            }
        }

        .dubaiThumb {
            .container {
                background-image: url(../images/png/house-2.jpeg);
                background-position: bottom !important;
            }
        }

        .malaysiaThumb {
            .container {
                background-image: url(../images/png/house-3.jpeg);
                background-position: bottom !important;
            }
        }

        .maldivThumb {
            .container {
                background-image: url(../images/png/pvc-doors.jpeg);
                background-position: bottom !important;
            }
        }

        // .italyThumb {
        //     .container {
        //         background-image: url(../images/png/house-2.jpg);
        //     }
        // }

        // .franceThumb {
        //     .container {
        //         background-image: url(../images/png/house-3.jpg);
        //     }
        // }
    }
}

// banner end 

.main-heading {
    position: relative;
}

.main-heading>.main-text::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 45px;
    height: 2px;
    background: var(--themeColor);
    bottom: 0px;
}

.tStripe::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 43px;
    height: 2px;
    background: var(--themeColor);
}

.aboutFluid {
    padding-top: 80px;
}

.about-card-text {
    padding: 70px 100px;
}

.about-card-img {
    position: absolute;
    top: -35px;
    right: 130px;
}

.tabNavBtn:hover {
    color: var(--themeColor);

}

.tabNavBtn:hover::before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 3px;
    background: var(--themeColor);

}

// .ticketFluid {
//     padding-left: 0;
//     padding-right: 0;
//     padding-top: 65px;
//     padding-bottom: 0px;

//     &>.container {
//         padding-left: 0;
//         padding-right: 0;
//     }
// }

// .ticketWorld {
//     position: absolute;
//     top: -90px;
//     width: auto;
//     right: 90px;
//     z-index: -1;
// }

.ticketContainer {
    position: relative;
    background-image: url(../images/png/ticketBgEn.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0 20px 50px;


    .ticket-text-card {
        width: 640px;
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }


    .boardingWrapper {
        position: absolute;
        width: 230px;
        bottom: -6px;
        right: 150px;


    }

    .ticket-slider {
        position: relative;
        height: 397px;
    }

    .slick-arrow {
        display: none !important;
    }
}


.whyChooseFluid {
    &>.container {
        padding-left: 0;
        padding-right: 0;
    }


}

.product-bg {
    background-image: url(../images/png/product-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 85%;
    background-position: bottom;
}

.whyChooseWrapper {
    // padding: 120px 80px;
    background-image: url(../images/png/whyChooseBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    // height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 60px;

    .whyChooseHeading {
        z-index: 10;
    }

    .whyChooseTitle,
    .whyChooseText {
        display: flex;
        width: 100%;
        // justify-content: center;
        // text-align: center;
        color: var(--white);
    }

    .whyChooseTitle {
        font-size: 22px;
        font-family: var(--regular);
    }

    .whyChooseText {
        font-size: 30px;
        font-family: var(--semiBold);
        margin-top: 5px;
        color: var(--white);
    }

    .whyChooseRow {
        position: relative;
        justify-content: space-between;
        width: 100%;
        z-index: 2;
    }
}



.contactFluid {
    padding-top: 0px;
    padding-bottom: 0;

    .contactDesc {
        text-align: center;
        margin-top: 40px;
        padding: 0 250px 0 250px;
    }

    .contactContainer {
        display: flex;
        width: 100%;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 60px;
        border: 10px solid rgba(255, 255, 255, 0.5);
        background-clip: content-box;
        position: relative;
        z-index: 5;

        .contactRow {
            padding: 0 40px 0 40px;

            .contactFormWrapper {
                button {
                    margin-right: 0;
                    margin-left: auto;
                }
            }
        }

        .contactImg {
            position: absolute;
        }

        .contactImg1 {
            right: 20px;
            top: -180px;
        }

        .contactImg2 {
            left: 20px;
            top: -180px;
        }

    }
}

footer {
    background: var(--gradientDark);

    .container {

        padding: 20px 0 20px 0;

        .footer-list-container {
            .footer-list {
                a {
                    cursor: pointer;

                }
            }
        }
    }

    .footerContentContainer {
        display: flex;
        align-items: center;
        gap: 40px;
    }
}

button span {
    padding-bottom: 3px;
}

.inputCol {
    position: relative;
}

// .error-message {
//     position: absolute;
//     padding: 0px 0px 0 0px;
//     margin: 0px 0;
//     color: red !important;
//     font-size: 13px !important;
//     line-height: 20px;
//     bottom: -12px;
// }

// model 
.modal-content {
    background: var(--bg);


    .model-img {
        position: relative;
        text-align: center;

        & img {
            height: 480px !important;
            width: 50%;
        }

    }

    .model-img-guide {
        position: relative;

        & img {
            height: 400px !important;
        }

    }

}


.loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--white);
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--themeColor) transparent;
    animation: spin 1s infinite ease-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



@import 'responsive';